//@ts-ignore
import { VersioningStore } from '@vegga/front-store';
import axios from 'axios';

export function setMicroVersion() {
    let versions = [];
    //@ts-ignore
    let tokensURL = document.querySelector('[content="scripts-start"]').nextElementSibling.href;
    //@ts-ignore
    let dsURL = document.querySelector('[content="scripts-start"]').nextElementSibling.nextElementSibling.src;
    
    const scriptsRAW = document.querySelector('[content="scripts-end"]').previousElementSibling.innerHTML;
    let micros = JSON.parse(scriptsRAW).imports;

    if(tokensURL.split('latest').length > 1) {
        tokensURL = `${tokensURL.split('latest')[0]}latest/version`;
        setVersion(tokensURL, '@vegga/design-tokens', versions)
    } else {
        versions.push({name: '@vegga/design-tokens', version: 'v0.0.0'});
    }

    if(dsURL.split('latest').length > 1) {
        dsURL = `${dsURL.split('latest')[0]}latest/version`;
        setVersion(dsURL, '@vegga/design-system', versions)
    } else {
        versions.push({name: '@vegga/design-system', version: 'v0.0.0'});
    }

    Object.entries(micros).forEach(([key, value]) => {
        if(key === '@vegga/front-core') {
            if((value as string).split('vegga-front-core.js').length > 1) {
                const url = `${(value as string).split('vegga-front-core.js')[0]}version`;
                setVersion(url, key, versions)
            } else {
                versions.push({name: key, version: 'v0.0.0'});
            }
        } else {
            if((value as string).split('latest').length > 1) {
                const url = `${(value as string).split('latest')[0]}latest/version`;
                setVersion(url, key, versions)
            } else {
                versions.push({name: key, version: 'v0.0.0'});
            }
        }
      });

    const versionStore = VersioningStore.getInstance();
    versionStore.storeVersion(versions);
}

function setVersion(url, key, versions) {
    axios.get(url)
    .then(res => {
        versions.push({name: key, version: res.data});    
    })
    .catch(error => {
        versions.push({name: key, version: 'v0.0.0'});
    })
}